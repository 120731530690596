<template>
  <el-dialog title="生成计划" :visible.sync="dialogVisible" width="60%" :before-close="handleClose">
    <el-form v-model="addFrom" label-width="100px">
      <el-form-item label="发工资月份">
        <el-date-picker
          v-model="addFrom.recordData"
          type="month"
          value-format="yyyy-MM"
          placeholder="选择月"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel()">取 消</el-button>
      <el-button type="primary" @click="createPlanFormAButton">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { createPlanFormApi } from "@/api/work/salary/salaryDetails";
export default {
  name: "SalaryDetailsInfo",
  data() {
    return {
      dialogVisible: false,
      addFrom: {}
    };
  },
  methods: {
    //打开弹窗
    show() {
      this.dialogVisible = true;
    },
    //
    ok() {
      this.$emit("closeModal", false);
      this.$Message.info("Clicked ok");
    },
    cancel() {
      this.addFrom = {};
      this.dialogVisible = false;
      this.$parent.$parent.questionnaireId=null;
      this.$parent.$parent.id=null;
        this.$parent.$parent.getSalaryDetails();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          this.cancel();
          // done();
        })
        .catch(_ => {});
    },
    createPlanFormAButton() {
      if (this.addFrom.recordData == undefined)
        return this.$message.error("请选择月份");
      createPlanFormApi({ recordData: this.addFrom.recordData }).then(res => {
        this.cancel();
      });
    }
  }
};
</script>
<style lang="less" scoped>
</style> 
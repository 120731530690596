
import { getAction,getByIdAction,postAction,putAction,getByTypeAction,deleteAction } from '@/api/common/request'
const baseURL = 'xun/work/xunSalaryDetails'
//调用 import { borrowList } from "@/api/work/salary/salaryDetails";

const add=(params)=>postAction(baseURL+'/add',params);

const selectSalaryById=(params)=>getByIdAction(baseURL+'/selectSalaryById',params);

const selectSalaryByIdV1=(params)=>getAction(baseURL+'/v1/selectSalaryById',params);

const edit=(params)=>postAction(baseURL+'/edit',params);

const salaryDetailsList=(params)=>getAction(baseURL+"/v1/list",params);

const getPlanMoneyByMonth=(params)=>getAction(baseURL+"/getPlanMoneyByMonth",params);

const createPlanFormApi=(params)=>getAction(baseURL+'/createPlanForm',params);

// 数据归档
const fileDetails=(params)=>getAction(baseURL+'/fileDetails',params);

const refreshDataApi=(params)=>getAction(baseURL+'/refreshData',params);

const deleteDataApi=(params)=>deleteAction(baseURL+'/delete',params);

export{
    add,
    deleteDataApi,
    selectSalaryById,
    edit,
    fileDetails,
    salaryDetailsList,
    getPlanMoneyByMonth,
    createPlanFormApi,
    refreshDataApi,
    selectSalaryByIdV1
}